body {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
}
h1,h2,h3,h4,h5,h6{margin: 0px;}
* {
    -webkit-transition: all ease-in-out 0.4s;
    -o-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
}
.menuLink, .menuLink *{
    -webkit-transition: all ease-in-out 0s;
    -o-transition: all ease-in-out 0s;
    transition: all ease-in-out 0s;
}

i {
    -webkit-transition: all ease-in-out -100000s;
    -o-transition: all ease-in-out -100000s;
    transition: all ease-in-out -100000s;
}
ul {
    margin: 0px;
    padding: 0px;
}
* ::-webkit-scrollbar {
    width: 5px;
}

* ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

* ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}
input, a, button{
    -webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;
}

a,
a:hover,
a:focus,
button,
button:hover,
button:focus {
    text-decoration: none;
    outline: none;
}
.w-auto {
    width: auto !important;
}
.align-padding {
    padding: 40px 0px 20px;
}
a:hover {
    color: var(--colorWite);
}

.veryBigFont{font-size: 2.9rem;}
.smallFont{font-size: 1.3rem;}
.headerMiduamFont{font-size: 1.8rem;}
/*.smallMidumFont{font-size: 1rem}*/
figure, figcaption{margin: 0px;}
.smallFntInr{font-size: 1rem;}
.verysmallFont{font-size: .8rem;}
p{font-size: 1.04rem;}

.fontWeightSemibold{font-weight: 600;}
.fontWeightSemiboldLight{font-weight: 700;}
.fontWeightSemibolder{font-weight: 800;}
.fontWeightbold{font-weight: 500;}
.fontWeightlight{font-weight: 200;}
.fontWeightMiduamlight{font-weight: 300;}
.font-weight400{font-weight: 400;}
:root {
    --blackColor: #0F2420;
    --offWhiteColor:#e8f0f1;
    --colorWhite:#fff;
    --pinkColor:#4B28FF;
    --lightWhiteColor:#F3FAFC;
    --textColor:#0f242080;
    --brightWhite:rgb(15 36 32 / 40%);
    /* --yellowColor:; */
    --deepFotClor:#0A2229;
}
.loadingBlue{color: blue;}
.alignPadding{padding: 3rem 0px;}
.lightWhiteColor{background: var(--lightWhiteColor);}
.textColor{color: var(--textColor);}
.brightWhite{color: var(--brightWhite);}
.bgWhite{background: var(--colorWhite);}
.colorWhite{color: var(--colorWhite);}
.yellowColor{color: #F3DD6D}
.blackColorBack{background-color: var(--blackColor);}
.blackColor{color: var(--blackColor);}
.deepFotColor{background-color: var(--deepFotClor);}
.minHeight100{min-height: 100vh;}

.whiteBack{background-color: #fff;}

.logoMenu{max-width: 220px;}
.topMenuSec .container{padding: 15px 10px;border-bottom: 1px solid var(--offWhiteColor);}
.menuLink{display: inline-block;}
.menuLink ul li{padding: 0px 1rem;}
.menuLink ul li a{font-size: .9rem;color: var(--blackColor);font-weight: 500;}
.btnTheme{padding: .7rem 1.2rem;background: var(--blackColor);border-radius: 8px;color: var(--colorWhite);cursor:pointer}
.btnTheme:hover{background: var(--pinkColor);color: var(--colorWhite);}
.backSecond{background: #31374c;}

/* banner section */
.banerSection{}
.bannerImage figure{max-width: 550px; margin: auto;}
.gredientBtn a{background: -o-linear-gradient(left, rgb(75.01, 40.34, 255) 0%, rgb(255, 112.28, 156.02) 100%);background: -webkit-gradient(linear, left top, right top, from(rgb(75.01, 40.34, 255)), to(rgb(255, 112.28, 156.02)));background: linear-gradient(90deg, rgb(75.01, 40.34, 255) 0%, rgb(255, 112.28, 156.02) 100%);padding: 12px 15px;font-size: 1rem; color: var(--colorWhite);text-transform: uppercase;border-radius: 5px;font-size: .9rem;}
.gredientBtn a:hover{background: -o-linear-gradient(50deg, rgb(75.01, 40.34, 255) 0%, rgb(255, 112.28, 156.02) 100%);background: linear-gradient(40deg, rgb(75.01, 40.34, 255) 0%, rgb(255, 112.28, 156.02) 100%);}
.backgroundAdd{background: -o-linear-gradient(bottom, #FFF8FB 0%, #EDEDFF 100%);background: -webkit-gradient(linear, left bottom, left top, from(#FFF8FB), to(#EDEDFF));background: linear-gradient(0deg, #FFF8FB 0%, #EDEDFF 100%);}

.customerRivewSec .container::before{content: '';position: absolute;left: 200px;right: 200px;background: -o-linear-gradient(bottom, #FFF8FB 0%, #EDEDFF 100%);background: -webkit-gradient(linear, left bottom, left top, from(#FFF8FB), to(#EDEDFF));background: linear-gradient(0deg, #FFF8FB 0%, #EDEDFF 100%);top: 0px;bottom: 0px;}
.customerRivewSec .container{position: relative;z-index: 2;}
.customerSec{padding: 20px 50px}
.topStarPart figure{max-width: 70px;overflow: hidden;border-radius: 100px;}

.myCustomAccordion .accordion-button:not(.collapsed){background: none;-webkit-box-shadow: none;box-shadow: none}
.myCustomAccordion .accordion-item{border: 0px;border-bottom: 1px solid var(--offWhiteColor);}
.myCustomAccordion .accordion-item .accordion-button h2{color: #C4C4C4;}
.myCustomAccordion .accordion-item.backgroundAdd .accordion-button h2{color: var(--bs-accordion-color);}

.foterLogo{max-width: 200px;}
.footerBtnText .btnTheme{background:var(--offWhiteColor);color: var(--blackColor);font-weight: 600;}
.footerBtnText .btnTheme:hover{background: var(--pinkColor); color: #fff;}
.borderBottom{border-bottom: 1px solid #2c3a4a;}
.footerLink ul li{padding-bottom: 7px;}
.footerLink ul li a{color: rgb(255 255 255 / 53%);font-weight: 200;}
.footerLink ul li a:hover{color: #fff;}
.endFooterSecContent .footerLink ul a{color: #fff; font-size: 1rem;}
.endFooterSecContent .footerLink ul a:hover{color: rgb(255 255 255 / 53%)}
.newsLeterSec{border: 0px;min-height: 45px;max-width: 260px;width: 100%;padding: 0px 10px;background: rgb(14 42 50);border: 1px solid rgb(208 236 245 / 36%);color: #D0ECF5;}
.newsLeterSec:focus{outline: none;}
.newsUbmit{width: 40px;height: 45px;border: 0px;background: #D0ECF5;}


.listMenuSec ul li{margin-bottom: 10px;padding: 15px 15px;background: #E9F5F9;border-radius: 10px;}
.listMenuSec ul li a{color: var(--blackColor);}
.listMenuSec ul li a:hover{color:#00698C;}
.recentLinks .borderBottom{border-color: var(--offWhiteColor);}
.iconList ul li a{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center; white-space: nowrap; overflow: hidden; -o-text-overflow: ellipsis; text-overflow: ellipsis;}
.iconList ul li a > span{width: 25px; margin-right: 10px;}
.iconList ul li a > span img{width: 30px;max-width: 30px;}
.iconList ul li{background: var(--colorWhite);}

.chatSection{min-height: calc(100vh - 223px);max-height: calc(100vh - 223px); padding: 0px 20px; overflow-y: auto;}
.profileImageChat img{width: 30px;height: 30px; border-radius: 50%;}
.rightUserSec{max-width: 400px;width: 100%;}
.rightUserSec figcaption{display: inline-block;background: #BAF0FF;padding: 10px 15px;border-radius: 50px 50px 0px 50px;margin-bottom: 5px;}
.leftSendMessageSec figcaption{display: block;background: #fff;padding: 20px 15px;border-radius: 30px 50px 50px 00px;margin-bottom: 5px;}
.chatInputSec input{min-height: 50px;border-radius: 50px;border: none;padding: 10px 20px;}
.chatBtn button{padding: 0px;background: #fff;border: 0px;border-radius: 50%;height: 50px;width: 50px;height: 50px;margin: 0px !important;}
.chatBtn button:hover, .chatBtn button:focus, .chatBtn button:active{background: #fff !important;}
.chatBtn button img{max-width: 30px;}
.menuCollapseBar{display: none !important;}
.floatBtnRounded{padding: 3px 0px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 12px;}

.backChatIner{background-image: url(../images/chatBack.png);background-size: 440px; min-height: 80vh;background-position: top center; background-repeat: no-repeat;}
.colorPink{color: #A354D7;}
.eachSecInerContent{border-bottom: 1px solid #000;}
.leftIconIner{display: inline-block; width: 30px;height: 30px;background: url(../images/more.png);background-size: cover; background-position: center;background-repeat: no-repeat; vertical-align: middle; position: relative;left: -3px;top: -3px;}
.eachSecInerContent h4{vertical-align: middle;}
.limit{background: url(../images/Stop.png);background-size: cover; background-position: center;background-repeat: no-repeat;}
.cap{background: url(../images/Capabilities.png);background-size: cover; background-position: center;background-repeat: no-repeat;}


.inerTetDiv{background: var(--colorWhite);padding:25px 20px;border-radius: 5px;}
.rightIcon{max-width: 50px;font-size: 1.3rem;}
.inerTetDiv h5{font-weight: 500;line-height: 25px;}
.inerDivBackGredient{background: linear-gradient(90deg, #FFD7F1 0%, #CFD2FF 100%);}

.loaderImage figure{max-width: 500px; margin:100px auto 0px;}

.thirdSmallImage figure{max-width: 350px; }
.topMenuSec{position: sticky; top: 0px; z-index: 99;}



.bottomFixedBtn{    position: fixed;
    z-index: 999;
    /* top: 0px; */
    bottom: 30px;
    right: 40px;
    max-width: 70px;}
.bottomFixedBtn:hover{ transform: scale(1.2);}
.bottomchatSec{position: fixed;right: 30px;bottom: 40px;max-width: 900px;width: 100%;z-index: 0999;border-radius: 15px;transform: translate(950px, 10px) scale(0);opacity: 0;visibility: hidden;}
.showModal{transform: translate(0px, 0px) scale(1);opacity: 1;visibility: visible;}


.bottomchatSec .chatSection {min-height: calc(65vh - 210px);max-height: calc(65vh - 210px);}
.bottomchatSec i{transition: all ease-in-out 0s;}
.chamodalLogo{max-width: 200px;}
.rightSideIconModal{vertical-align: middle;}
.rightSideIconModal p a{font-size: 17px; color: var(--blackColor); }
.inerLinkContentDiv{position: relative;
    background: #E1F5FE;
    padding: 5px;
    border-radius: 10px;
    margin: 2px;
    padding-left: 35px;
    border: 1px solid #e2e2e2;}
.leftLinkIcon{position: absolute;
    left: 13px;
    top: 6px;font-size: .9rem;}

.listMenuSec{
    max-height: calc(100vh - 270px);
    overflow-x: auto;
    padding: 10px;
}
.eachSecInerContent img{
    max-width: 40px;
    max-height: 40px;
    object-fit: cover;
    margin: 0px 10px;
}
.inerLinkContentDiv h6 a{white-space: normal;word-break: break-all;font-size: .9rem;}
.shareList .smallFont{font-size: 1rem;}
.loadingChatImage{max-width:30px}
.chatbsck{min-height: calc(100vh - 102px);}
.heightSec{min-height: calc(100vh - 80px);}
.cursor-pointer{cursor: pointer;}
.eachDivInerSec .inerTetDiv{min-height: 100px;display: flex;}
.eachDivInerSec .inerTetDiv .d-flex{cursor: pointer;}
.logoMenu{cursor: pointer;}
.editIcon{max-width: 25px;}
@media(min-width:1499px){
    .veryBigFont {
        font-size: 3.4rem;
    }
}
@media(max-width:991px){
    .customerRivewSec .container::before{left: 60px;right: 60px;}
}
@media(max-width:767px){
    body{border-top: 0px !important;}
    .container, .container-sm {max-width: 100%;}
    .banerBigtext{text-align: center;}
    .frequentAskSec .row.align-items-end p.d-inline-block{display: block !important; text-align: center;}
    .btnTheme {padding: 0.5rem 1rem;}
    .veryBigFont {font-size: 2rem;}
    .gredientBtn a{padding: 8px 12px;font-size: .7rem;}
    .customerRivewSec .container::before{left: 0px;right: 0px;}
    .frequentAskSec .myCustomAccordion .col-1{margin-right: 15px;}
    .myCustomAccordion h4{font-size: 1.1rem;}
    .footerBtnText{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;margin-top: 20px;}
    .endFooterSecContent{text-align: center;}
    .footerLink{margin-top: 1rem;}
    .footerLink h6{margin-bottom: .3rem !important;}

    .menuLink{display: none;display: none;position: absolute;top: 70px;left: 0px;right: 0px;background: -webkit-gradient(linear, left bottom, left top, from(#FFF8FB), to(#EDEDFF));background: -o-linear-gradient(bottom, #FFF8FB 0%, #EDEDFF 100%);background: linear-gradient(0deg, #FFF8FB 0%, #EDEDFF 100%);border-bottom: 1px solid #e0d9d9;}
    .menuLink ul{margin: 0px !important; display: block !important;padding: 10px 0px;}
    .menuLink ul li{width: 100%;text-align: left;padding: 10px 15px;}
    .container.alignPadding{padding-right: calc(var(--bs-gutter-x) * .5);padding-left: calc(var(--bs-gutter-x) * .5);}
    .menuCollapseBar{display: inline-block !important;}
    .mobileNone{display: none;}
}

@media(max-width:575px){
    .headerMiduamFont, .veryBigFont {font-size: 1.8rem;}
    .smallFont {font-size: .8rem;}
    .frequentAskSec .myCustomAccordion .col-1{margin-right: 20px;}
    .btnTheme {padding: 0.4rem 0.7rem;}
    .footerBtnText p.d-inline-block{min-width: 120px;}
    .footerMy .footerBtnText{display: block;}
    .footerMy .footerBtnText .btnTheme{margin-top: 20px !important;}
}